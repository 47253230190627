import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import * as Sentry from '@sentry/react'
import Theme from '@/components/template/Theme'
import Layout from '@/components/layouts'
import mockServer from './mock'
import history from './history'
import appConfig from '@/configs/app.config'
import './locales'
import { v4 as uuidv4 } from 'uuid'
import { isEmpty } from 'lodash'
import useClarity from '@/utils/hooks/useClarity';
import { CopilotKit } from '@copilotkit/react-core';
import {
    load as loadIntercom,
    boot as bootIntercom,
    update as updateIntercom,
} from './intercom';

const environment = import.meta.env.VITE_NODE_ENV

if (environment !== "production" && appConfig.enableMock) {
    mockServer({ environment })
} else if (environment === "production" || environment === "staging") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        replaysSessionSampleRate:
            parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0.1') || 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.replayIntegration(),
        ],
        environment: environment,
    });
}

history.listen((location) => {
    // Calls Intercom('update') on every page change
    updateIntercom();
});

function App() {
    const clarityId = import.meta.env.VITE_CLARITY_ID;
    const clientId = store.getState().auth?.user?.client_id;
    const email = store.getState().auth.user.email;

    useClarity(clarityId, clientId, email);

    loadIntercom();
    const user_id = store.getState().auth.user.id;
    const userName = store.getState().auth.user.userName;
    Sentry.setUser({ email: email });
    const threadId = uuidv4();

    if (isEmpty(email) && isEmpty(user_id)) {
        bootIntercom();
    } else {
        bootIntercom({
            email: email,
            user_id: user_id,
            userName: userName,
            client_id: store.getState().auth.user.client_id,
            organization: localStorage.getItem("ORGANIZATION_NAME"),
            created_at: store.getState().auth.user.created_at,
            signed_up: store.getState().auth.user.created_at,
            company: {
                id: store.getState().auth.user.client_id,
                name: localStorage.getItem("ORGANIZATION_NAME"),
                plan: localStorage.getItem("PLAN"),
                created_at: localStorage.getItem("ORG_CREATED_AT"),
                monthly_spend: localStorage.getItem("MONTHLY_PAYMENT"),
                last_payment_date: localStorage.getItem("LAST_PAYMENT_DATE"),
                last_payment_amount: `$${localStorage.getItem("LAST_PAYMENT_AMOUNT")}`,
                organization_status: localStorage.getItem("ORG_STATUS"),
            },
        })
    }

    return (
        <Sentry.ErrorBoundary showDialog>
            <CopilotKit
                runtimeUrl={import.meta.env.VITE_COPILOT_URL}
                properties={{
                    email: email,
                    thread_id: threadId,
                    client_id: store.getState().auth.user.client_id,
                    user_id: user_id
                }}
            >
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter>
                            <Theme>
                                <Layout />
                            </Theme>
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </CopilotKit>
        </Sentry.ErrorBoundary>
    )
}

export default App
